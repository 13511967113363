import {
    ACTIVE_SEASONAL_EVENTBRITE_CATEGORY,
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    BUSINESS_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    EVENTBRITE_CATEGORY_IMAGES,
    EVENTBRITE_CATEGORY_IMAGES_SMALL,
    EVENTBRITE_CATEGORY_MAPPING,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    FASHION_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
    OTHER_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    POLITICS_EVENTBRITE_CATEGORY,
    RELIGION_EVENTBRITE_CATEGORY,
    SCHOOL_EVENTBRITE_CATEGORY,
    SCIENCE_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
    TRAVEL_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { gettext } from '@eventbrite/i18n';
import { EDGY, MELLOW, SMART, TRANSPARENT, VIBRANT } from './colorThemes';
const CATEGORY_COLOR_THEMING: Record<string, string[]> = {
    [EDGY]: [
        FASHION_EVENTBRITE_CATEGORY,
        PERFORMING_EVENTBRITE_CATEGORY,
        SPORTS_EVENTBRITE_CATEGORY,
        FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
        TRAVEL_EVENTBRITE_CATEGORY,
    ],
    [SMART]: [
        SCIENCE_EVENTBRITE_CATEGORY,
        BUSINESS_EVENTBRITE_CATEGORY,
        AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
        HOBBIES_EVENTBRITE_CATEGORY,
        SCHOOL_EVENTBRITE_CATEGORY,
    ],
    [MELLOW]: [
        POLITICS_EVENTBRITE_CATEGORY,
        RELIGION_EVENTBRITE_CATEGORY,
        FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
        CHARITY_EVENTBRITE_CATEGORY,
        HEALTH_EVENTBRITE_CATEGORY,
    ],
    [VIBRANT]: [
        MUSIC_EVENTBRITE_CATEGORY,
        FILM_EVENTBRITE_CATEGORY,
        COMMUNITY_EVENTBRITE_CATEGORY,
        HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    ],
    [TRANSPARENT]: [NIGHTLIFE_EVENTBRITE_CATEGORY],
};

const getColorTheme = (categoryKey: string): string => {
    const themeKey = Object.keys(CATEGORY_COLOR_THEMING).find((key) =>
        CATEGORY_COLOR_THEMING[key].includes(categoryKey),
    );

    return themeKey || MELLOW;
};

const CATEGORY_BROWSE_MUSIC_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[MUSIC_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Music events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[MUSIC_EVENTBRITE_CATEGORY],
    imageUrlSmall: EVENTBRITE_CATEGORY_IMAGES_SMALL[MUSIC_EVENTBRITE_CATEGORY],
    theme: getColorTheme(MUSIC_EVENTBRITE_CATEGORY),
    key: MUSIC_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_NIGHTLIFE_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[NIGHTLIFE_EVENTBRITE_CATEGORY],
    subtitle: 'Electrify your nights with parties, music, comedy, and more.',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[NIGHTLIFE_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[NIGHTLIFE_EVENTBRITE_CATEGORY],
    key: NIGHTLIFE_EVENTBRITE_CATEGORY,
    theme: getColorTheme(NIGHTLIFE_EVENTBRITE_CATEGORY),
};

const CATEGORY_BROWSE_SEASONAL_EVENTS_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[ACTIVE_SEASONAL_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Seasonal events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[NIGHTLIFE_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[NIGHTLIFE_EVENTBRITE_CATEGORY],
    key: NIGHTLIFE_EVENTBRITE_CATEGORY,
    theme: getColorTheme(ACTIVE_SEASONAL_EVENTBRITE_CATEGORY),
};

const CATEGORY_BROWSE_BUSINESS_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[BUSINESS_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Business events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[BUSINESS_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[BUSINESS_EVENTBRITE_CATEGORY],
    theme: getColorTheme(BUSINESS_EVENTBRITE_CATEGORY),
    key: BUSINESS_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_PERFORMING_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[PERFORMING_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best Performing & Visual Arts events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[PERFORMING_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[PERFORMING_EVENTBRITE_CATEGORY],
    theme: getColorTheme(PERFORMING_EVENTBRITE_CATEGORY),
    key: PERFORMING_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_FILM_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[FILM_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Film & Media events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[FILM_EVENTBRITE_CATEGORY],
    imageUrlSmall: EVENTBRITE_CATEGORY_IMAGES_SMALL[FILM_EVENTBRITE_CATEGORY],
    theme: getColorTheme(FILM_EVENTBRITE_CATEGORY),
    key: FILM_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_SPORTS_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[SPORTS_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best Sports & Fitness events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[SPORTS_EVENTBRITE_CATEGORY],
    imageUrlSmall: EVENTBRITE_CATEGORY_IMAGES_SMALL[SPORTS_EVENTBRITE_CATEGORY],
    theme: getColorTheme(SPORTS_EVENTBRITE_CATEGORY),
    key: SPORTS_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_FOOD_AND_DRINK_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Food & Drink events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
    theme: getColorTheme(FOOD_AND_DRINK_EVENTBRITE_CATEGORY),
    key: FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_HOBBIES_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[HOBBIES_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Hobbies events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[HOBBIES_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[HOBBIES_EVENTBRITE_CATEGORY],
    theme: getColorTheme(HOBBIES_EVENTBRITE_CATEGORY),
    key: HOBBIES_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_HEALTH_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[HEALTH_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Health events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[HEALTH_EVENTBRITE_CATEGORY],
    imageUrlSmall: EVENTBRITE_CATEGORY_IMAGES_SMALL[HEALTH_EVENTBRITE_CATEGORY],
    theme: getColorTheme(HEALTH_EVENTBRITE_CATEGORY),
    key: HEALTH_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_TRAVEL_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[TRAVEL_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best Travel & Outdoor events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[TRAVEL_EVENTBRITE_CATEGORY],
    imageUrlSmall: EVENTBRITE_CATEGORY_IMAGES_SMALL[TRAVEL_EVENTBRITE_CATEGORY],
    theme: getColorTheme(TRAVEL_EVENTBRITE_CATEGORY),
    key: TRAVEL_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_FASHION_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[FASHION_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Fashion events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[FASHION_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[FASHION_EVENTBRITE_CATEGORY],
    theme: getColorTheme(FASHION_EVENTBRITE_CATEGORY),
    key: FASHION_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_SCIENCE_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[SCIENCE_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Science & Tech events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[SCIENCE_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[SCIENCE_EVENTBRITE_CATEGORY],
    theme: getColorTheme(SCIENCE_EVENTBRITE_CATEGORY),
    key: SCIENCE_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_CHARITY_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[CHARITY_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best Charity & Causes events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[CHARITY_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[CHARITY_EVENTBRITE_CATEGORY],
    theme: getColorTheme(CHARITY_EVENTBRITE_CATEGORY),
    key: CHARITY_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_POLITICS_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[POLITICS_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Government events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[POLITICS_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[POLITICS_EVENTBRITE_CATEGORY],
    theme: getColorTheme(POLITICS_EVENTBRITE_CATEGORY),
    key: POLITICS_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_COMMUNITY_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[COMMUNITY_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Community events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[COMMUNITY_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[COMMUNITY_EVENTBRITE_CATEGORY],
    theme: getColorTheme(COMMUNITY_EVENTBRITE_CATEGORY),
    key: COMMUNITY_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_RELIGION_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[RELIGION_EVENTBRITE_CATEGORY],
    subtitle: 'Discover the best Spirituality events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[RELIGION_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[RELIGION_EVENTBRITE_CATEGORY],
    theme: getColorTheme(RELIGION_EVENTBRITE_CATEGORY),
    key: RELIGION_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_FAMILY_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[FAMILY_EDUCATION_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best Family & Education events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[FAMILY_EDUCATION_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[FAMILY_EDUCATION_EVENTBRITE_CATEGORY],
    theme: getColorTheme(FAMILY_EDUCATION_EVENTBRITE_CATEGORY),
    key: FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_SEASONAL_HEADER = {
    title: gettext('Holiday'),
    subtitle: 'Discover the best Holiday events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[SEASONAL_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[SEASONAL_EVENTBRITE_CATEGORY],
    theme: getColorTheme(SEASONAL_EVENTBRITE_CATEGORY),
    key: SEASONAL_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_HOME_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[HOME_LIFESTYLE_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best Home & Lifestyle events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[HOME_LIFESTYLE_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[HOME_LIFESTYLE_EVENTBRITE_CATEGORY],
    theme: getColorTheme(HOME_LIFESTYLE_EVENTBRITE_CATEGORY),
    key: HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_AUTO_BOAT_AIR_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[AUTO_BOAT_AIR_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best Auto, Boat & Air events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[AUTO_BOAT_AIR_EVENTBRITE_CATEGORY],
    imageUrlSmall:
        EVENTBRITE_CATEGORY_IMAGES_SMALL[AUTO_BOAT_AIR_EVENTBRITE_CATEGORY],
    theme: getColorTheme(AUTO_BOAT_AIR_EVENTBRITE_CATEGORY),
    key: AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_SCHOOL_HEADER = {
    title: EVENTBRITE_CATEGORY_MAPPING[SCHOOL_EVENTBRITE_CATEGORY],
    subtitle:
        'Discover the best School Activities events in your area and online',
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[SCHOOL_EVENTBRITE_CATEGORY],
    imageUrlSmall: EVENTBRITE_CATEGORY_IMAGES_SMALL[SCHOOL_EVENTBRITE_CATEGORY],
    theme: getColorTheme(SCHOOL_EVENTBRITE_CATEGORY),
    key: SCHOOL_EVENTBRITE_CATEGORY,
};

const CATEGORY_BROWSE_OTHER_HEADER = {
    title: gettext('Eclectic'),
    imageUrl: EVENTBRITE_CATEGORY_IMAGES[OTHER_EVENTBRITE_CATEGORY],
    imageUrlSmall: EVENTBRITE_CATEGORY_IMAGES_SMALL[OTHER_EVENTBRITE_CATEGORY],
    theme: getColorTheme(HOBBIES_EVENTBRITE_CATEGORY),
    key: OTHER_EVENTBRITE_CATEGORY,
};

export const PARENT_CATEGORY_CONTENT_MAP = {
    [MUSIC_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_MUSIC_HEADER,
    [BUSINESS_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_BUSINESS_HEADER,
    [PERFORMING_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_PERFORMING_HEADER,
    [FILM_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_FILM_HEADER,
    [SPORTS_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_SPORTS_HEADER,
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_FOOD_AND_DRINK_HEADER,
    [HOBBIES_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_HOBBIES_HEADER,
    [HEALTH_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_HEALTH_HEADER,
    [TRAVEL_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_TRAVEL_HEADER,
    [FASHION_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_FASHION_HEADER,
    [SCIENCE_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_SCIENCE_HEADER,
    [CHARITY_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_CHARITY_HEADER,
    [POLITICS_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_POLITICS_HEADER,
    [COMMUNITY_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_COMMUNITY_HEADER,
    [RELIGION_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_RELIGION_HEADER,
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_FAMILY_HEADER,
    [SEASONAL_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_SEASONAL_HEADER,
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_HOME_HEADER,
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_AUTO_BOAT_AIR_HEADER,
    [SCHOOL_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_SCHOOL_HEADER,
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_NIGHTLIFE_HEADER,
    [ACTIVE_SEASONAL_EVENTBRITE_CATEGORY]:
        CATEGORY_BROWSE_SEASONAL_EVENTS_HEADER,
    [OTHER_EVENTBRITE_CATEGORY]: CATEGORY_BROWSE_OTHER_HEADER,
};
