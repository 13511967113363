import { Icon } from '@eventbrite/eds-icon';
import { ChevronRightChunky as ChevronRightChunkySvg } from '@eventbrite/eds-iconography';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    ARTICLE_TEMPLATE,
    CARD_TEMPLATE_4,
    CARD_TEMPLATE_8,
    CATEGORY_TEMPLATE,
    EVENT_TIME_OPTIONS_TEMPLATE,
    LARGE_CARD_TEMPLATE_3,
    LARGE_CARD_TEMPLATE_6,
    LIST_CARD_TEMPLATE,
    TRENDING_SEARCHES_TEMPLATE,
} from '../../constants';
import { useEnvContext } from '../../context';

const HIDE_SEE_MORE_MOBILE_TEMPLATES = [
    LARGE_CARD_TEMPLATE_3,
    LARGE_CARD_TEMPLATE_6,
    CARD_TEMPLATE_4,
    CARD_TEMPLATE_8,
    ARTICLE_TEMPLATE,
    CATEGORY_TEMPLATE,
    TRENDING_SEARCHES_TEMPLATE,
    LIST_CARD_TEMPLATE,
    EVENT_TIME_OPTIONS_TEMPLATE,
];

const LinkedBucketHeaderTitle = ({
    linkUrl,
    title,
    icon,
    seeMoreMarkedNoFollow = false,
    enableClientSideLinking,
}: {
    linkUrl: string;
    title: JSX.Element | React.ReactNode | string;
    icon: JSX.Element | React.ReactNode;
    seeMoreMarkedNoFollow?: boolean;
    enableClientSideLinking?: boolean;
}) => {
    linkUrl = useLocalePrefixLinkUrl(linkUrl) || '';

    return enableClientSideLinking ? (
        <h2>
            <Link
                className="eds-text-hs eds-text-color--grey-700"
                to={linkUrl}
                aria-label={gettext('View more %(title)s', {
                    title,
                }).toString()}
                rel={seeMoreMarkedNoFollow ? 'nofollow' : ''}
                data-testid="bucket-see-more"
            >
                {title}
                {icon}
            </Link>
        </h2>
    ) : (
        <h2>
            <a
                className="eds-text-hs eds-text-color--grey-700"
                href={linkUrl}
                aria-label={gettext('See more %(title)s', { title }).toString()}
                rel={seeMoreMarkedNoFollow ? 'nofollow' : ''}
                data-testid="bucket-see-more"
            >
                {title}
                {icon}
            </a>
        </h2>
    );
};

const UnlinkedBucketHeaderTitle = ({
    title,
    icon,
}: {
    title: JSX.Element | React.ReactNode | string;
    icon: JSX.Element | React.ReactNode;
}) => {
    return (
        <h2 className="eds-text-hs eds-text-color--grey-700">
            {title}
            {icon}
        </h2>
    );
};

const SeeMore = ({
    linkUrl,
    template,
    title,
    text,
    enableClientSideLinking,
    seeMoreMarkedNoFollow = false,
}: {
    linkUrl?: string;
    template: string;
    title: JSX.Element | React.ReactNode | string;
    text: string;
    seeMoreMarkedNoFollow?: boolean;
    enableClientSideLinking?: boolean;
}) => {
    linkUrl = useLocalePrefixLinkUrl(linkUrl);
    let component = null;

    if (linkUrl) {
        const seeMoreClasses = classNames(
            'see-more-link',
            'eds-link--neutral',
            'eds-text--right',
            'eds-g-cell',
            'eds-g-cell-3-12',
            {
                'eds-show-up-sm':
                    HIDE_SEE_MORE_MOBILE_TEMPLATES.indexOf(template) > -1,
            },
        );
        const anchorText = text ? text : gettext('Explore more events');

        component = (
            <a
                className={seeMoreClasses}
                href={linkUrl}
                aria-label={gettext('See more %(title)s', { title }).toString()}
                rel={seeMoreMarkedNoFollow ? 'nofollow' : ''}
                data-testid="bucket-see-more"
            >
                <span>{anchorText}</span>
                <Icon type={<ChevronRightChunkySvg />} color="grey-600" />
            </a>
        );

        if (enableClientSideLinking) {
            component = (
                <Link
                    className={seeMoreClasses}
                    to={linkUrl}
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    aria-label={gettext('View more %(title)s', {
                        title,
                    }).toString()}
                    rel={seeMoreMarkedNoFollow ? 'nofollow' : ''}
                    data-testid="bucket-see-more"
                >
                    <span>{anchorText}</span>
                    <Icon type={<ChevronRightChunkySvg />} color="grey-600" />
                </Link>
            );
        }
    }

    return component;
};

function useLocalePrefixLinkUrl(linkUrl?: string) {
    const { env } = useEnvContext();
    if (linkUrl && env?.localeInfo?.uses_language_subdirectory) {
        return prefixLocaleToPaths(linkUrl, env?.localeInfo.locale);
    }
    return linkUrl;
}

const prefixLocaleToPaths = (linkUrl: string, locale?: string): string => {
    if (linkUrl.startsWith('https')) {
        return prefixLocaleToUrl(linkUrl, locale);
    } else if (linkUrl.startsWith('/b') && locale) {
        return `/${locale}${linkUrl}`;
    }
    return linkUrl;
};

const prefixLocaleToUrl = (url: string, locale?: string): string => {
    // Parse the URL to get the parts
    const urlObject = new URL(url);

    const { pathname } = urlObject;
    const newPathname = `/${locale}${pathname}`;
    const newUrl = `${urlObject.protocol}//${urlObject.host}${newPathname}`;

    return newUrl;
};

interface BucketHeaderProps {
    title?: string | GenericLazyString;
    linkUrl?: string;
    template?: string;
    seeMoreText?: string;
    seeMoreMarkedNoFollow?: boolean;
    bucketKey?: string;
    iconType?: JSX.Element;
    enableClientSideLinking?: boolean;
}

export class BucketHeader extends React.PureComponent<BucketHeaderProps> {
    render() {
        const {
            title,
            linkUrl,
            template,
            seeMoreText,
            iconType,
            seeMoreMarkedNoFollow,
            enableClientSideLinking,
        } = this.props;

        if (!title) {
            return null;
        }

        let icon = null;

        if (iconType) {
            icon = (
                <span className="eds-l-pad-left-2 eds-l-pad-bot-1">
                    <Icon
                        type={iconType}
                        width="36px"
                        height="36px"
                        color="grey-700"
                    />
                </span>
            );
        }

        const headerTitleClasses = classNames(
            'bucket-header__title',
            'eds-g-cell',
            'eds-g-cell-9-12',
            'eds-l-pad-bot-2',
            'eds-l-lg-pad-bot-4',
            'eds-l-lw-pad-bot-4',
            'eds-l-ln-pad-bot-4',
        );

        return (
            <div className="eds-align--center-vertical eds-l-pad-hor-3">
                <div className={headerTitleClasses}>
                    {linkUrl ? (
                        <LinkedBucketHeaderTitle
                            linkUrl={linkUrl}
                            title={title}
                            icon={icon}
                            seeMoreMarkedNoFollow={seeMoreMarkedNoFollow}
                            enableClientSideLinking={enableClientSideLinking}
                        />
                    ) : (
                        <UnlinkedBucketHeaderTitle title={title} icon={icon} />
                    )}
                </div>
                <SeeMore
                    linkUrl={linkUrl}
                    template={template || ''}
                    title={title}
                    text={seeMoreText || ''}
                    seeMoreMarkedNoFollow={seeMoreMarkedNoFollow}
                    enableClientSideLinking={enableClientSideLinking}
                />
            </div>
        );
    }
}

export default BucketHeader;
