import { track } from '@eventbrite/datalayer-library';
import { Button } from '@eventbrite/marmalade';
import React, { useEffect, useMemo } from 'react';
import { Sticky } from 'react-sticky';
import {
    PLACE_ID_TO_VALENTINES_COLLECTION_MAP,
    VALENTINES,
} from '../../pages/categoryBrowse/constants/collectionTouchpoint';
import { SEASONAL_RIBBON_CONFIG } from '../../pages/categoryBrowse/constants/seasonal';
import './SeasonalRibbon.scss';

export interface SeasonalRibbonProps {
    season: 'halloween' | 'holidays' | 'nye' | typeof VALENTINES;
    isMobile?: boolean;
    locationSlug: string;
    placeId?: string;
    country?: string;
    browseSurface?: string;
    isSticky?: boolean;
}

const MOBILE_OFFSET = '119px';
const OFFSET = '61px';

export const SeasonalRibbon = (props: SeasonalRibbonProps) => {
    const {
        isMobile,
        locationSlug,
        country,
        browseSurface,
        season,
        placeId,
        isSticky = true,
    } = props;

    const seasonalData = SEASONAL_RIBBON_CONFIG[season];
    const isGeoEligible = useMemo(() => {
        return (
            (country && seasonalData.eligibleCountries.includes(country)) ||
            (placeId && seasonalData.eligibleCities?.includes(placeId))
        );
    }, [country, placeId, seasonalData]);

    useEffect(() => {
        if (isGeoEligible) {
            track({
                eventName: 'SeasonalRibbonView',
                eventData: {
                    season: season,
                    location: locationSlug,
                    browseSurface: browseSurface || 'default',
                },
            });
        }
    }, [isGeoEligible, season, locationSlug, browseSurface]);
    if (!isGeoEligible) {
        return null;
    }
    const handleClick = () => {
        track({
            eventName: 'SeasonalRibbonClick',
            eventData: {
                season: season,
                location: locationSlug,
                browseSurface: browseSurface || 'default',
            },
        });
    };

    const ribbonCTA = () => {
        const isNotSearchOnValentines = !(
            browseSurface == 'search' && season == VALENTINES
        );
        return isNotSearchOnValentines
            ? seasonalData.ctaLink(locationSlug)
            : seasonalData.ctaCollectionLink &&
                  seasonalData.ctaCollectionLink(
                      PLACE_ID_TO_VALENTINES_COLLECTION_MAP[placeId || ''],
                  );
    };

    const Ribbon = () => {
        return (
            <div
                className="seasonal-ribbon"
                style={{ backgroundColor: seasonalData.styles.backgroundColor }}
            >
                <div className="seasonal-ribbon-container">
                    <div
                        className="seasonal-ribbon-title-container"
                        data-testid="seasonal-ribbon-title"
                    >
                        <h2
                            className="eds-text-bm"
                            style={{
                                color: seasonalData.styles.textColor,
                            }}
                        >
                            {isMobile
                                ? seasonalData.ribbonTitleMobile
                                : seasonalData.ribbonTitleDesktop}
                        </h2>
                    </div>
                    <div className="seasonal-ribbon-cta">
                        <a
                            href={ribbonCTA()}
                            data-heap-id={`seasonal_ribbon_click_${browseSurface}`}
                            onClick={handleClick}
                        >
                            <Button
                                className="seasonal-ribbon-button"
                                aria-label="Find holiday events"
                                style={{
                                    color: seasonalData.styles.buttonTextColor,
                                }}
                            >
                                <span className="eds-text-bm">
                                    {isMobile
                                        ? seasonalData.ribbonCtaTextMobile
                                        : seasonalData.ribbonCtaTextDesktop}
                                </span>
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    if (!isSticky) {
        return <Ribbon />;
    }

    return (
        <Sticky topOffset={-50}>
            {({ style }) => (
                <div
                    style={{
                        ...style,
                        top: isMobile ? MOBILE_OFFSET : OFFSET,
                        zIndex: 490,
                    }}
                >
                    <Ribbon />
                </div>
            )}
        </Sticky>
    );
};

export default SeasonalRibbon;
