import {
    DATING_EVENTBRITE_SUB_CATEGORY,
    HALLOWEEN_EVENTBRITE_SUB_CATEGORY,
    INDEPENDENCE_DAY_EVENTBRITE_SUB_CATEGORY,
    LGBT_EVENTBRITE_SUB_CATEGORY,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
    NYE_EVENTBRITE_SUB_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { PLACES } from '../../../constants/seasonalFeatured';
import { default as halloween_touchpoint_mobile } from '../../../static/images/browse/halloween_touchpoint_image.webp';
import INDEPENDENCE_ICON from '../../../static/images/browse/independence_day.webp';
import DATING_ICON from '../../../static/images/browse/maria.webp';
import { default as nye_touchpoint_mobile } from '../../../static/images/browse/nye_touchpoint_image.webp';
import pride_touchpoint_mobile from '../../../static/images/browse/pride_touchpoint_image_mobile.webp';
import { default as holiday_touchpoint_mobile } from '../../../static/images/browse/updated_holiday_touchpoint_image.webp';
import { default as valentine_touchpoint } from '../../../static/images/browse/valentine_touchpoint.webp';
const PLACE_ID_TO_DATING_COLLECTION_MAP: { [key: string]: string } = {
    [PLACES.CHICAGO]: 'ccmxczxq',
    [PLACES.LOS_ANGELES]: 'ccmxczyf',
    [PLACES.WASHINGTON_DC]: 'ccmxczyx',
    [PLACES.SEATTLE_WA]: 'ccmxczzm',
    [PLACES.MIAMI]: 'ccmxdbbc',
    [PLACES.NEW_YORK]: 'ccmxczbm',
};
const PLACE_ID_TO_INDEPENDENCE_COLLECTION_MAP: {
    [key: string]: string;
} = {
    [PLACES.ATLANTA]: 'ccmxdbcz',
    [PLACES.LOS_ANGELES]: 'ccmxcxhq',
    [PLACES.WASHINGTON_DC]: 'ccmxcxgh',
    [PLACES.MIAMI]: 'ccmxdbch',
    [PLACES.NEW_YORK]: 'ccmxcxgz',
    [PLACES.SAN_FRANCISCO]: 'ccmxcxkf',
    [PLACES.CHICAGO]: 'ccmxcxkx',
    [PLACES.HOUSTON]: 'ccmxdbdq',
    [PLACES.DALLAS]: 'ccmxdbff',
    [PLACES.BOSTON_MA]: 'ccmxdbbt',
};

const PLACE_ID_TO_PRIDE_COLLECTION_MAP: { [key: string]: string } = {
    '85923517': 'ccmxcwyc',
    '85931779': 'ccmxcwzh',
    '85940195': 'ccmxcwyt',
    '85977539': 'cchkwfgf',
    '101750367': 'cchkwfhm',
    '85922583': 'cchkwfgx',
    '85936429': 'ccmxczkc',
    '101724577': 'ccmxczkt',
    '85949461': 'ccmxczmh',
    '85950361': 'ccmxczmz',
    '85981333': 'ccmxczrm',
    '101724385': 'ccmxcztc',
    '85928879': 'ccmxcztt',
    '101725629': 'ccmxczwz',
    '85933669': 'ccmxczwh',
    '101718083': 'ccmxczqx',
    '101730401': 'ccmxczqf',
    '85931789': 'ccmxczpq',
    '101735835': 'ccmxczhm',
    '101741075': 'ccmxczgx',
};

const PLACE_ID_TO_HALLOWEEN_COLLECTION_MAP: { [key: string]: string } = {
    [PLACES.BOSTON_MA]: 'ccmxdbrc',
    [PLACES.CHICAGO]: 'ccmxdbkz',
    [PLACES.WASHINGTON_DC]: 'ccmxdbpf',
    [PLACES.HOUSTON]: 'ccmxdbrt',
    [PLACES.LOS_ANGELES]: 'ccmxdbmq',
    [PLACES.LONDON]: 'ccmxdbht',
    [PLACES.MIAMI]: 'ccmxdbqm',
    [PLACES.NEW_YORK]: 'ccmxdbhc',
    [PLACES.SAN_FRANCISCO]: 'ccmxdbkh',
    [PLACES.ATLANTA]: 'ccmxdbpx',
};
const PLACE_ID_TO_HOLIDAY_COLLECTION_MAP: { [key: string]: string } = {
    [PLACES.BOSTON_MA]: 'ccmxdccx',
    [PLACES.CHICAGO]: 'ccmxctxf',
    [PLACES.WASHINGTON_DC]: 'ccmxctwq',
    [PLACES.LOS_ANGELES]: 'ccmxcttz',
    [PLACES.LONDON]: 'ccmxctym',
    [PLACES.MELBOURNE_AU]: 'ccmxdcdm',
    [PLACES.MIAMI]: 'ccmxdcfc',
    [PLACES.NEW_YORK]: 'ccmxctth',
    [PLACES.SAN_FRANCISCO]: 'ccmxctxx',
    [PLACES.TORONTO_CA]: 'ccmxdcft',
};
const PLACE_ID_TO_NYE_COLLECTION_MAP: { [key: string]: string } = {
    [PLACES.BOSTON_MA]: 'ccmxdcxt',
    [PLACES.CHICAGO]: 'cwtgcwp',
    [PLACES.WASHINGTON_DC]: 'cwtgcrb',
    [PLACES.LOS_ANGELES]: 'cwtgcpk',
    [PLACES.LONDON]: 'cwtppzk',
    [PLACES.MELBOURNE_AU]: 'ccmxdcyh',
    [PLACES.MIAMI]: 'cwtgcwb',
    [PLACES.NEW_YORK]: 'cwtgckw',
    [PLACES.SAN_FRANCISCO]: 'cwtgmcb',
    [PLACES.TORONTO_CA]: 'ccmxdcyz',
};

export const PLACE_ID_TO_VALENTINES_COLLECTION_MAP: { [key: string]: string } =
    {
        [PLACES.BOSTON_MA]: 'ccmxddxh',
        [PLACES.CHICAGO]: 'ccmxcwkq',
        [PLACES.WASHINGTON_DC]: 'ccmxcymc',
        [PLACES.ATLANTA]: 'ccmxddwc',
        [PLACES.LOS_ANGELES]: 'ccmxcwpm',
        [PLACES.LONDON]: 'ccmxcwmf',
        [PLACES.MIAMI]: 'ccmxddwt',
        [PLACES.NEW_YORK]: 'ccmxcwhz',
        [PLACES.SAN_FRANCISCO]: 'ccmxcykm',
        [PLACES.TORONTO_CA]: 'ccmxddtm',
    };

export const VALENTINES = 'valentines';

interface CollectionTouchpointProps {
    placeId_collection_map: { [key: string]: string };
    title?: GenericLazyString;
    subtitle?: string;
    description?: GenericLazyString;
    eventBucketLabel: string;
    iconSrc: string;
    buttonTitle: string;
    dataHeapId: string;
    dataTestId?: string;
}

export const COLLECTION_TOUCHPOINT_DATA: {
    [key: string]: CollectionTouchpointProps;
} = {
    [DATING_EVENTBRITE_SUB_CATEGORY]: {
        placeId_collection_map: PLACE_ID_TO_DATING_COLLECTION_MAP,
        title: gettext('EXPERT PICKS'),
        subtitle: 'Meet your match',
        description: gettext(
            'Date ideas from Matchmaker Maria, CEO of Agape Matchmaking',
        ),
        eventBucketLabel: 'maria_touchpoint',
        iconSrc: DATING_ICON,
        buttonTitle: 'Explore',
        dataHeapId: 'datingPage-mariaCollection',
    },
    [INDEPENDENCE_DAY_EVENTBRITE_SUB_CATEGORY]: {
        placeId_collection_map: PLACE_ID_TO_INDEPENDENCE_COLLECTION_MAP,
        title: gettext("EDITOR'S PICKS"),
        subtitle: 'Make your Fourth a blast',
        description: gettext(''),
        eventBucketLabel: 'independence_touchpoint',
        iconSrc: INDEPENDENCE_ICON,
        buttonTitle: 'Explore %(currentPlace)s events',
        dataHeapId: 'independence-day-collection',
    },
    [LGBT_EVENTBRITE_SUB_CATEGORY]: {
        placeId_collection_map: PLACE_ID_TO_PRIDE_COLLECTION_MAP,
        title: gettext("EDITOR'S PICKS"),
        subtitle: 'Find your Pride in %(currentPlace)s',
        description: gettext('by Eventbrite'),
        eventBucketLabel: 'pride_touchpoint',
        iconSrc: pride_touchpoint_mobile,
        buttonTitle: 'Explore',
        dataHeapId: 'pride_page_touchpoint_click',
        dataTestId: 'pride-page-touchpoint-heading-title-subtitle',
    },
    [HALLOWEEN_EVENTBRITE_SUB_CATEGORY]: {
        placeId_collection_map: PLACE_ID_TO_HALLOWEEN_COLLECTION_MAP,
        subtitle: 'We’ve got exciting Halloween happenings all month long. ',
        eventBucketLabel: 'halloween_touchpoint',
        iconSrc: halloween_touchpoint_mobile,
        buttonTitle: 'Find Your Thing',
        dataHeapId: 'halloween_page_touchpoint_click',
        dataTestId: 'halloween-page-touchpoint-heading-title-subtitle',
    },
    [SEASONAL_EVENTBRITE_CATEGORY]: {
        placeId_collection_map: PLACE_ID_TO_HOLIDAY_COLLECTION_MAP,
        subtitle:
            'Find special outings that bring magic and wonder to the season.',
        eventBucketLabel: 'holiday_touchpoint',
        iconSrc: holiday_touchpoint_mobile,
        buttonTitle: 'Plan Your Holiday',
        dataHeapId: 'holiday_page_touchpoint_click_holidaycategory',
        dataTestId: 'holiday-page-touchpoint-heading-title-subtitle',
    },
    [NYE_EVENTBRITE_SUB_CATEGORY]: {
        placeId_collection_map: PLACE_ID_TO_NYE_COLLECTION_MAP,
        subtitle:
            'From fireworks to ball drops, discover the best local New Year’s Eve bashes.',
        eventBucketLabel: 'nye_touchpoint',
        iconSrc: nye_touchpoint_mobile,
        buttonTitle: 'Plan Your NYE',
        dataHeapId: 'holiday_page_touchpoint_click_nyesubcategory',
        dataTestId: 'holiday-page-touchpoint-heading-title-subtitle',
    },
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: {
        placeId_collection_map: PLACE_ID_TO_VALENTINES_COLLECTION_MAP,
        subtitle: 'Go Straight to the Heart: Memorable Valentine’s Day ideas',
        eventBucketLabel: 'nightlife_touchpoint',
        iconSrc: valentine_touchpoint,
        buttonTitle: 'Plan Your Valentine’s',
        dataHeapId: 'nightlife_page_touchpoint_click',
        dataTestId: 'nightlife-page-touchpoint-heading-title-subtitle',
    },
};
