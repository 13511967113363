/* eslint-disable react/jsx-handler-names */
import { BaseDiscoverState, CUSTOM_DATE } from '@eventbrite/discover-utils';
import {
    FOLLOWED_ORGS_FILTER_VALUE,
    getSelectedCategory,
    getSelectedFilterCount,
    getSelectedFormat,
    getSelectedSubcategories,
    openCloseAnimatedDrawer as openCloseAnimatedDrawerAction,
    setFilterTypeToShow as setFilterTypeToShowAction,
} from '@eventbrite/search-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import loadable from '@loadable/component';
import { Location } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
    handleApplyFilter as handleApplyFilterAction,
    handleRemoveAllFilters as handleRemoveAllFiltersAction,
    handleRemoveFilter as handleRemoveFilterAction,
    handleRemoveOnlineFilter,
    searchByBBoxOrPlace as searchByBBoxOrPlaceAction,
} from '../../../../redux/actions/search';
import { getSelectedDate } from '../../../../redux/selectors/search';
//todo: properly handle loadable once experiment concluded
import { SelectedFilters } from '@eventbrite/search-utils';
import { FilterPanelDesktop } from '../../components/FilterPanel/FilterPanelDesktop';
import { FilterPanelMobile } from '../../components/FilterPanel/FilterPanelMobile';
import { FilterHeaderContainer } from '../FilterHeaderContainer/FilterHeaderContainer';
import { trackFiltersForStatsig } from './analytics';

const BotFilters = loadable(() => import('@eventbrite/search-utils'), {
    resolveComponent: (component: { BotFilters: React.ReactNode }) =>
        component.BotFilters,
});

interface FilterPanelWrapperProps {
    /**
     * Callbacks for when filters are applied
     */
    handleApplyFilter?: Function;
    handleRemoveFilter?: Function;
    /**
     * Boolean for if mobile filter panel should be displayed
     */
    isAnimatedDrawerOpen?: boolean;
    selectedFilterCount?: number;
    openCloseAnimatedDrawer?: Function;
    selectedFilters: SelectedFilters;
    locationSlug?: string;
    isBotRequest?: boolean;
    handleRemoveAllFilters?: Function;
    isAuthenticated?: boolean;
    isLoading?: boolean;
    languageCode?: string;
    handleApplyOnlineFilter?: Function;
    handleRemoveOnlineFilter?: Function;
    location: Location;
    filterTypeToShow?: string;
    locale: string;
    usesLanguageSubdirectory: boolean;
}

export const FilterPanelWrapper: React.FunctionComponent<
    FilterPanelWrapperProps
> = (props) => {
    const {
        selectedFilterCount,
        handleApplyFilter,
        handleRemoveFilter,
        isAnimatedDrawerOpen,
        openCloseAnimatedDrawer,
        selectedFilters,
        locationSlug,
        isBotRequest,
        handleRemoveAllFilters,
        isAuthenticated,
        isLoading,
        languageCode,
        handleApplyOnlineFilter,
        handleRemoveOnlineFilter,
        location,
        locale,
        usesLanguageSubdirectory,
    } = props;

    const handleChange = React.useCallback(
        (key: string, value: string) => {
            trackFiltersForStatsig(key);
            handleApplyFilter?.({ [key]: value }, {});
        },
        [handleApplyFilter],
    );

    const handleCustomDate = React.useCallback(
        (startDate: string, endDate: string) => {
            handleApplyFilter?.({
                dates: CUSTOM_DATE,
                dateRange: { startDate, endDate },
            });
        },
        [handleApplyFilter],
    );

    const SHARED_FILTER_PROPS = {
        isAuthenticated,
        location,
        languageCode,
        selectedFilterCount: selectedFilterCount,
        selectedFilters: selectedFilters,
        isLoading: isLoading,
        handleRemoveAllFilters: handleRemoveAllFilters,
        handleRemoveFilter,
        handleApplyOnlineFilter,
        handleRemoveOnlineFilter,
        onFilterChange: handleChange,
        onCustomDateChange: handleCustomDate,
        locale: locale,
        usesLanguageSubdirectory: usesLanguageSubdirectory,
    };

    return (
        <>
            <aside
                className="filter-panel-desktop-container"
                data-testid="filter-panel-desktop-container"
            >
                {isBotRequest ? (
                    <div className="filter-panel">
                        <BotFilters slug={props.locationSlug} />
                    </div>
                ) : (
                    <FilterPanelDesktop
                        {...SHARED_FILTER_PROPS}
                        locationSlug={locationSlug || ''}
                        locale={locale}
                        isMobile={false}
                    />
                )}
            </aside>
            {isAnimatedDrawerOpen && (
                <div>
                    <FilterPanelMobile
                        {...SHARED_FILTER_PROPS}
                        isMobile={true}
                        locationSlug={locationSlug || ''}
                        filterTypeToShow={props.filterTypeToShow}
                        openCloseAnimatedDrawer={openCloseAnimatedDrawer}
                        headerChildren={
                            <FilterHeaderContainer
                                hideActiveFilterLabel={true}
                                shouldRenderInactivePills={false}
                            />
                        }
                        locale={locale}
                    />
                    <style
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                            __html: `
                                    .eds-structure {
                                        position: fixed;
                                        width: 100%;
                                        height: 100%;
                                    }
                                `,
                        }}
                    />
                </div>
            )}
        </>
    );
};

const _mapStateToProps = (state: BaseDiscoverState) => ({
    locale: state.app.locale,
    selectedFilterCount: getSelectedFilterCount(state),
    isAnimatedDrawerOpen: state.ui.isAnimatedDrawerOpen,
    filterTypeToShow: state.ui.filterTypeToShow,
    isBotRequest: state.app.isBotRequest,
    locationSlug: state.location.slug,
    isAuthenticated: state.auth.isAuthenticated,
    languageCode: state.app.languageCode,
    selectedFilters: {
        price: state.search.eventSearch.price,
        category: getSelectedCategory(state.search.eventSearch.tags),
        subcategories: getSelectedSubcategories(state.search.eventSearch.tags),
        format: getSelectedFormat(state.search.eventSearch.tags),
        dates: getSelectedDate(
            state.search.eventSearch.dates,
            state.search.eventSearch.dateRange,
        ),
        currencies:
            state.search.eventSearch.currencies.length > 0
                ? state.search.eventSearch.currencies[0]
                : '',
        languages:
            state.search.eventSearch.languages.length > 0
                ? state.search.eventSearch.languages
                : '',
        followedOrganizers:
            state.search.eventSearch.special &&
            state.search.eventSearch.special?.indexOf(
                FOLLOWED_ORGS_FILTER_VALUE,
            ) > -1,
        organizations: state.search.eventSearch?.organizationsOr
            ? state.search.eventSearch.organizationsOr
            : [],
        hash: state.search.eventSearch.hash,
        isOnline: state.search.eventSearch.onlineEventsOnly,
        experiences: state.search.eventSearch.experiences,
    },
});

const _mapDispatchToProps = (dispatch: Function) => ({
    handleApplyFilter: (...args: [$FixMe]) =>
        dispatch(handleApplyFilterAction(...args)),
    handleRemoveFilter: (...args: [$FixMe]) =>
        dispatch(handleRemoveFilterAction(...args)),
    handleRemoveAllFilters: () => dispatch(handleRemoveAllFiltersAction()),
    openCloseAnimatedDrawer: (...args: [$FixMe]) =>
        dispatch(openCloseAnimatedDrawerAction(...args)),
    setFilterTypeToShowAction: (...args: [$FixMe]) =>
        dispatch(setFilterTypeToShowAction(...args)),
    handleApplyOnlineFilter: (...args: [$FixMe]) =>
        dispatch(searchByBBoxOrPlaceAction(...args)),
    handleRemoveOnlineFilter: () => dispatch(handleRemoveOnlineFilter()),
});

const enhance = compose<any>(
    withRouter,
    connect(_mapStateToProps, _mapDispatchToProps),
);

export const FilterPanelContainer = enhance(FilterPanelWrapper);
