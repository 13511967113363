import {
    DEFAULT_DESTINATION_EXPANSIONS,
    DestinationEvent,
} from '@eventbrite/event-renderer';
import { sdkRequest } from '@eventbrite/http';

export type EventExpansionsApiResponse = { events: DestinationEvent[] };

export const DEFAULT_EXPANSIONS = [
    'event_sales_status',
    'image',
    'primary_venue',
    'saves',
    'ticket_availability',
    'primary_organizer',
    'public_collections',
];

const DESTINATION_EVENTS_ENDPOINT = '/api/v3/destination/events/';
const BFF_EVENTS_ENDPOINT = '/d/api/events/';
export const DESTINATION_EVENTS_SLA_MILLISECONDS = 3000; // 2x the P95 of the destination/events/ endpoint

export async function fetchEventExpansions(
    eventIds: string[] = [],
    expansions = DEFAULT_DESTINATION_EXPANSIONS,
    useBff = false,
): Promise<EventExpansionsApiResponse> {
    const eventIdsJoined = eventIds.join(',');
    const params = `event_ids=${eventIdsJoined}&page_size=${eventIds.length}&expand=${expansions}`;

    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
    }, DESTINATION_EVENTS_SLA_MILLISECONDS);

    try {
        let response;
        if (useBff) {
            response = await fetchEventsBff(
                `${BFF_EVENTS_ENDPOINT}?${params}`,
                controller.signal,
            );
        } else {
            response = await sdkRequest(
                `${DESTINATION_EVENTS_ENDPOINT}?${params}`,
                {
                    signal: controller.signal,
                },
            );
        }
        return response;
    } catch (e) {
        console.error(e);
        throw e;
    } finally {
        clearTimeout(id);
    }
}

export const fetchSearchEventExpansions = (
    eventIds: string[],
    useBff = false,
): Promise<EventExpansionsApiResponse> => {
    return fetchEventExpansions(eventIds, DEFAULT_EXPANSIONS, useBff);
};

async function fetchEventsBff(
    url: string,
    signal: AbortSignal,
): Promise<EventExpansionsApiResponse> {
    return fetch(url, {
        method: 'GET',
        signal,
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<EventExpansionsApiResponse>;
    });
}
