import { datadogRum } from '@datadog/browser-rum-slim';
import { DatadogAdsEvents } from '@eventbrite/ads';
import { CoreApplicationContext, CoreEnv } from '@eventbrite/context-gen';
import { ExperimentMap } from '@eventbrite/discover-utils';
import { EBEnvs, getCurrentEnv } from '@eventbrite/feature-detection';
import { addCustomRUMEvent } from '@eventbrite/real-user-monitoring';
import { configureStore } from '@eventbrite/redux-configure-store';
import { middleware as analyticsMiddleware } from '@eventbrite/site-analytics';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import thunk from 'redux-thunk';
import { ConfirmationOverlay } from '../../components/ConfirmationOverlay';
import { SeasonalRibbon } from '../../components/SeasonalRibbon';
import { ConnectedBasePage } from '../../containers/ConnectedBasePage';
import reducer from '../../redux/reducers';
import { getInitialData } from '../../redux/reducers/utils';
import { FeatureFlags, FooterLinksShape } from '../../types';
import { ApiSearchData } from '../../types/Search';
import '../app.scss';
import {
    PLACE_ID_TO_VALENTINES_COLLECTION_MAP,
    VALENTINES,
} from '../categoryBrowse/constants/collectionTouchpoint';
import { Breadcrumb, SearchBreadCrumb } from './containers/SearchBreadCrumb';
import './SearchPage.scss';
import { SearchPageContent } from './SearchPageContent';
import { useInitialEventsExpansion } from './SearchPageDataExpansions';
import { SearchPageProviders } from './SearchPageProviders';
import { useSearchBff } from './utils/featureFlags';
import { SEARCH_PLACEMENT_NAME } from './utils/tracking';

type SearchPageProps = RouteComponentProps & {
    env: CoreApplicationContext['env'];
    app_version?: string;
    app_name: string;
    user: {
        isAuthenticated?: boolean;
        publicId?: string;
        email?: string;
        isStaff?: boolean;
    };
    experiments: ExperimentMap;
    request: { session_id: string };
    breadcrumbs?: Breadcrumb[];
    search_data: ApiSearchData;
    location: Location;
    featureFlags?: FeatureFlags;
    footerLinks: FooterLinksShape;
    location_slug?: string;
    country?: string;
    placeId?: string;
};

const getInitialEventsIds = (searchData: ApiSearchData) => {
    const {
        events: { results = [], top_match = [] },
    } = searchData;

    const events = [...results, ...top_match];
    const eventIds = events.map(({ id }) => id);
    return eventIds;
};

function SearchPage(props: SearchPageProps) {
    const {
        env,
        location,
        search_data,
        request,
        user,
        featureFlags,
        footerLinks,
        breadcrumbs,
        location_slug,
        country,
        placeId,
    } = props;

    // TODO: Adjust dependencies of these memoizations
    // getInitialData requires a lot of props that are not currently being mapped
    // Sadly, using props as key is not valid, since it changes reference with every route change
    const initialState = useMemo(
        () => getInitialData(props),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const store = useMemo(
        () =>
            configureStore({
                reducer,
                initialState,
                middleware: [thunk, analyticsMiddleware(props)],
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useDataDogEffects(env);
    useDataDogAdTracking(search_data, env);

    const eventIds = getInitialEventsIds(search_data);
    const useBff = useSearchBff(featureFlags?.enableSearchBff, 'events');
    useInitialEventsExpansion(
        eventIds,
        store.getState().search.eventSearch,
        useBff,
    );

    return (
        <SearchPageProviders store={store} location={location}>
            <ConnectedBasePage
                env={env}
                user={user}
                request={request}
                featureFlags={featureFlags}
                footerLinks={footerLinks}
                removeOverflowSettings
            >
                <ConfirmationOverlay />
                <div className="feed-search-page">
                    <StickyContainer style={{ overflowY: 'auto' }}>
                        {featureFlags?.enableSeasonalModule &&
                        location_slug &&
                        placeId &&
                        country &&
                        placeId in PLACE_ID_TO_VALENTINES_COLLECTION_MAP ? (
                            <SeasonalRibbon
                                season={VALENTINES}
                                isMobile={env.isMobile}
                                placeId={placeId}
                                locationSlug={location_slug}
                                country={country}
                                browseSurface="search"
                            />
                        ) : null}
                        {breadcrumbs ? (
                            <SearchBreadCrumb
                                containerClasses="breadcrumbs"
                                breadcrumbs={breadcrumbs}
                            />
                        ) : null}
                        <SearchPageContent />
                    </StickyContainer>
                </div>
            </ConnectedBasePage>
        </SearchPageProviders>
    );
}

function useDataDogEffects(env: CoreApplicationContext['env']) {
    const isProdEnv = getCurrentEnv({ env }) === EBEnvs.PROD;

    useEffect(() => {
        if (!isProdEnv) {
            return;
        }
        datadogRum.onReady(() =>
            datadogRum.setGlobalContextProperty('view.sub_app', 'search'),
        );
    }, [isProdEnv]);
}

function useDataDogAdTracking(searchData: ApiSearchData, env: CoreEnv) {
    const searchSSRPromotedEvents = searchData?.events?.promoted_results;

    useEffect(() => {
        if (!searchSSRPromotedEvents || searchSSRPromotedEvents.length === 0) {
            return;
        }

        datadogRum.onReady(() => {
            addCustomRUMEvent(DatadogAdsEvents.SSRObtained, {
                value: searchSSRPromotedEvents.length,
                app: 'web',
                is_mobile: env.isMobile,
                interface: SEARCH_PLACEMENT_NAME,
            });
        });
    }, [searchSSRPromotedEvents, env.isMobile]);
}

export default SearchPage;
