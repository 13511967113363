import {
    EVENTBRITE_CATEGORY_MAPPING,
    getParentCategoryFromSubCategory,
} from '@eventbrite/categories';
import { Icon } from '@eventbrite/eds-icon';
import { Link } from '@eventbrite/eds-link';
import { gettext } from '@eventbrite/i18n';
import {
    CATEGORY_FILTER,
    constructFilterItemKey,
    EVENTBRITE_CATEGORY_MAPPING_SEARCH,
    FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP,
    FilterProps,
    FilterSection,
    FilterSectionProps,
    getCanonicalSearchUrl,
    RadioType,
    SUBCATEGORIES_FILTER,
} from '@eventbrite/search-utils';
import React from 'react';
import { CATEGORY_LONG_NAME_ICON_MAP } from '../../../../../constants';
import { CATEGORY_SUBCATEGORY_MAPPING_SEARCH } from '../../../constants';
import styles from './NestedCategoryFilters.module.scss';
import { NestedFilterItem } from './NestedFilterItem';

interface CategoryFilterItemProps extends RadioType {
    onClick: (value: any) => void;
    path: string;
}
function CategoryFilterItem({
    value,
    display,
    onClick,
    path,
}: CategoryFilterItemProps) {
    const icon =
        CATEGORY_LONG_NAME_ICON_MAP[
            value as keyof typeof CATEGORY_LONG_NAME_ICON_MAP
        ];
    return (
        <div className={styles.categoryOption}>
            <Link
                aria-label={display}
                onClick={(e: React.MouseEvent<HTMLLinkElement>) => {
                    e.preventDefault();
                    onClick(e);
                }}
                to={path}
                data-testid={`category-filter-${value}`}
            >
                {icon && <Icon type={icon} size="small" color="ui-blue" />}
                <span>{display}</span>
            </Link>
        </div>
    );
}

export const NestedCategoryFilters: React.FunctionComponent<FilterProps> = ({
    selectedFilters,
    onFilterChange,
    isMobile,
    filterTypeToShow,
    locationSlug,
    usesLanguageSubdirectory,
    locale,
}) => {
    const subcategories = selectedFilters?.[SUBCATEGORIES_FILTER];
    const parentCategories = subcategories?.map((subcategory) =>
        getParentCategoryFromSubCategory(subcategory),
    );

    /*
     * If a subcategory is selected, but the category was deselected
     * infer the category from the first subcategory
     * (This tries to prevent selecting subcategories across different categories)
     */
    const category =
        selectedFilters?.[CATEGORY_FILTER] || parentCategories?.[0];

    // Update the title and options to reflect the selected category
    let title = FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP[CATEGORY_FILTER].label;
    let activeFilters: FilterSectionProps['filters'] =
        EVENTBRITE_CATEGORY_MAPPING_SEARCH;
    if (category) {
        title = gettext('%(category)s categories', {
            category: EVENTBRITE_CATEGORY_MAPPING[category],
        });
        activeFilters = Object.fromEntries(
            Object.entries(activeFilters).filter(([k]) => k === category),
        );
    }

    return (
        <FilterSection
            filters={activeFilters}
            onOptionChange={(value: any) => {
                onFilterChange?.(CATEGORY_FILTER, value);
            }}
            title={title}
            activeFilterOption={category}
            isMobile={isMobile}
            shouldScrollIntoView={filterTypeToShow === CATEGORY_FILTER}
            tokens={{
                '--FilterSectionMargin': '3rem 0 0 0',
                '--FieldSetLegendPadding': '0 0 0.75rem 0',
                '--LiPadding': '12px 0 12px 0',
            }}
        >
            {({ value, display }: RadioType) => {
                // Hide this category if it is not selected
                if (category && value !== category) {
                    return null;
                }

                // Generate a URL to this category using any existing filters (for SEO)
                const path = getCanonicalSearchUrl({
                    ...selectedFilters,
                    // Convert format (if present) and this category into an array of tags
                    tags: [
                        ...(selectedFilters?.format
                            ? [selectedFilters.format]
                            : []),
                        value,
                    ],
                    slug: locationSlug,
                    usesLanguageSubdirectory: usesLanguageSubdirectory,
                    locale: locale,
                });

                return (
                    <li
                        key={constructFilterItemKey(
                            'category',
                            value,
                            isMobile,
                        )}
                    >
                        {category ? (
                            // If a category is selected, show only it's subcategories.
                            <NestedFilterItem
                                value={value}
                                display={display}
                                activeFilterOption={subcategories}
                                subFilterMap={
                                    CATEGORY_SUBCATEGORY_MAPPING_SEARCH
                                }
                                isMobile={isMobile}
                                onOptionChange={(value: any) => {
                                    onFilterChange?.(
                                        SUBCATEGORIES_FILTER,
                                        value,
                                    );
                                }}
                                tokens={{
                                    '--NestedFilterItemMargin': '0 0 0 0',
                                    '--FilterSectionMargin': '0 0 0 0',
                                    '--FieldSetLegendPadding': '0 0 0 0',
                                    '--LiPadding': '16px 0 0 0',
                                }}
                                initialToggleOpen={
                                    category === value ||
                                    parentCategories?.includes(value)
                                }
                            />
                        ) : (
                            // If no category is selected, show all categories
                            <CategoryFilterItem
                                value={value}
                                display={display}
                                onClick={() => {
                                    onFilterChange?.(CATEGORY_FILTER, value);
                                }}
                                path={path}
                            />
                        )}
                    </li>
                );
            }}
        </FilterSection>
    );
};
